import { 
  createBrowserRouter, 
  RouterProvider 
} from 'react-router-dom';

import App from './App';
import ErrorPage from './pages/ErrorPage';
import Landing from './pages/Landing';
import AboutPage from './pages/AboutPage';
import ContactMe from './pages/ContactMe'
import ScheduleForm from './pages/ScheduleForm';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Landing />,
      },
      {
        path: '/quem-somos',
        element: <AboutPage />,
      },
      {
        path: '/fale-conosco',
        element: <ContactMe />,
      },
      {
        path: '/agende-sua-consulta',
        element: <ScheduleForm />,
      },        
    ],
  },  
]);

function Routes() {
  return (
    <RouterProvider router={routes}/>
  );
}

export default Routes;
