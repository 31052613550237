import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Footer from '../../components/PageComponents/Footer';
import Mission from '../../components/PageComponents/Mission';
import Projects from '../../components/PageComponents/Projects';
import Header from '../../components/PageComponents/Header';

import './styles.css';

function Landing() {

  const gaEventTracker = useAnalyticsEventTracker('Página principal');

  useEffect(() => {
    gaEventTracker.sendPageView('/', 'Página principal');
  }, []);

  return (
    <div id="landing-page" className="container">
      <Header />
      <Projects />
      <Mission />     
      <Footer />
    </div>
  );
}

export default Landing;
