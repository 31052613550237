import React from "react";
import { Link } from 'react-router-dom';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import escola from '../../../assets/images/escola.png';
import cursos from '../../../assets/images/cursos.png';
import numerologia from '../../../assets/images/numerologia.png';
import agenda from '../../../assets/images/agenda.png';
import oCacadorDeSacerdotisas from '../../../assets/images/cacador_de_sacerdotisas.png';
import brigandoComDeus from '../../../assets/images/brigando_com_deus.png';
import analogiasECronicas from '../../../assets/images/analogias_e_cronicas.png';
import podcast from '../../../assets/images/podcast.png'
import contatosENegocios from '../../../assets/images/contatos_e_negocios.png';
import ebook from '../../../assets/images/ebook.png';
import livro from '../../../assets/images/livro.png';
import mapaFamosos from '../../../assets/images/mapa_famosos.png';

import './styles.css';

const Projects: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Truenumbers');

  return (
    <div className="projects">
      <div className="projects-content">
        <div className="projects-main">
          <div className="items">            
            <div className="item-box">
              <a href="https://www.escoladenumerologia.com.br" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar escola de numerologia', 'Visitar escola de numerologia')}>
                <div className="item">
                  <img src={escola} alt="escola_de_numerologia"/>
                  <div className="project-title">                    
                    <strong>ESCOLA DE NUMEROLOGIA</strong>                   
                  </div>               
                </div>
              </a> 
              <a href='https://famosos.truenumbers.com.br' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar mapa das personalidades famosas', 'Visitar mapa das personalidades famosas')}>
                <div className="item">
                  <img src={mapaFamosos} alt="mapa_dos_famosos"/>
                  <div className="project-title">
                    <div id='link-figuras'>
                      <strong id="mapa-figuras">ANÁLISE NUMEROLÓGICA</strong>
                      <strong id='mapa-figuras'>DAS PERSONALIDADES</strong>                  
                    </div>                                  
                  </div>
                </div>
              </a>                         
            </div>
            <div className="item-box">
              <Link to='/agende-sua-consulta' onClick={()=> gaEventTracker.eventTracker('Visitar agende sua consulta', 'Visitar agende sua consulta')}>
                <div className="item">
                  <img src={agenda} alt="agende_sua_consulta"/>
                  <div className="project-title">
                    <strong>AGENDE A SUA CONSULTA</strong>                         
                  </div>
                </div>
              </Link>
              <a href="https://manual.truenumbers.com.br" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar manual de numerologia', 'Visitar manual de numerologia')}>
                <div className="item">
                  <img src={livro} alt="manual_de_numerologia"/>
                  <div className="project-title">
                    <strong>MANUAL DE NUMEROLOGIA</strong>
                  </div>               
                </div>
              </a>             
            </div>            
            <div className="item-box">
              <a href="https://www.ocacadordesacerdotisas.com.br/" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar o cacador de sacerdotisas', 'Visitar o cacador de sacerdotisas')}>
                <div className="item">
                  <img src={oCacadorDeSacerdotisas} alt="a_cacador_de_sacerdotisas"/>
                  <div className="project-title">                    
                    <strong>O CAÇADOR DE SACERDOTISAS</strong>                                      
                  </div>
                </div>
              </a>
              <a href="https://www.brigandocomdeus.com.br/" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar brigando com deus', 'Visitar brigando com deus')}>  
                <div className="item">
                  <img src={brigandoComDeus} alt="brigando_com_deus"/>
                  <div className="project-title">                   
                    <strong>BRIGANDO COM DEUS</strong>                                      
                  </div>
                </div>
              </a>
            </div>
            <div className="item-box">
              <a href="https://youtube.com/playlist?list=PLUfGqfL_-olqS0DkGYEU8WqkgCtuqSIFl&si=ZVwIezZTihXTrL5E" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar analogias e cronicas no youtube', 'Visitar analogias e cronicas no youtube')}>  
                <div className="item">
                  <img src={analogiasECronicas} alt="analogias_e_cronicas"/>
                  <div className="project-title">                    
                    <strong>ANALOGIAS E CRÔNICAS</strong>                                      
                  </div>
                </div>
              </a>
              <a href="https://youtube.com/playlist?list=PLUfGqfL_-olr0pOaZs7fHg34PgSpVJtKq&si=0Hi9ieO9Qx3Hv22Y" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar podcast no youtube', 'Visitar podcast no youtube')}> 
                <div className="item">
                  <img src={podcast} alt="podcast"/>
                  <div className="project-title">
                    <strong>PODCAST</strong>                                      
                  </div>
                </div>
              </a>
            </div>
            <div className="item-box">
              <a href="https://clubedeautores.com.br/livros/autores/jaime-benedetti" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar loja clube de autores', 'Visitar loja clube de autores')}>  
                <div className="item">
                  <img src={ebook} alt="e-book_audiobook-livros"/>
                  <div className="project-title">                    
                    <strong>E-BOOK E LIVROS</strong>                                      
                  </div>
                </div>
              </a>
              <a href="https://www.contatosenegocios.com.br" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar contatos e negocios', 'Visitar contatos e negocios')}> 
                <div className="item">
                  <img src={contatosENegocios} alt="contatos_e_negocios"/>
                  <div className="project-title">                    
                    <strong>CONTATOS & NEGÓCIOS</strong>                                      
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
