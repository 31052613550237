import React from "react";

import './styles.css';

const Mission: React.FC = () => {
  return (
    <div className="mission">
      <div className="mission-content">
        <div className="text-box">
          <p>O GRUPO TRUENUMBERS é dedicado ao estudo, aplicação e</p>
          <p>educação em autoconhecimento, numerologia, e o</p>
          <p>desenvolvimento da consciência humana.</p>
        </div>
        <strong>NOSSA MISSÃO</strong>
        <div className="text-box">
          <p>A nossa missão é explorar a complexa simbologia dos números</p>
          <p>e suas aplicações práticas, e compartilhar essa riqueza de</p>
          <p>conhecimento com o mundo através de nossos cursos,</p>
          <p>workshops e publicações.</p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
