import { Link } from 'react-router-dom';

import './styles.css';

function ErrorPage() {
  return (
    <div id="error-page" className="container">
      <div className='error-container'>
        <h2>Error 404</h2>
        <h3>Página não encontrada!</h3>
        <Link to='/'>
          <p>Voltar</p>
        </Link>
      </div>       
    </div>
  );
}

export default ErrorPage;
