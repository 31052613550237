import React from "react";
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logos/logo.png';
import jaime from '../../../assets/images/fotos/jaime.png';
import ingrid from '../../../assets/images/fotos/ingrid.png';

import './styles.css'; 

const AboutHeader: React.FC = () => {
  return (
    <header className="about-header">
      <div className="about-header-content">
        <Link to='/' >
          <img id='logo' src={logo} alt="logo_truenumbers"/>
        </Link>        
        <div className="fotos">
          <img id="ingrid" src={ingrid} alt="Ingrid"/>
          <img id="jaime" src={jaime} alt="Jaime"/>
        </div>
        <div className="text-box">
          <p>A BUSCA DO AUTOCONHECIMENTO E O DESENVOLVIMENTO</p>
          <p>DA CONSCIÊNCIA HUMANA.</p>  
        </div>                       
      </div>
    </header>
  );
};

export default AboutHeader;
