import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Footer from '../../components/PageComponents/Footer';
import FooterBanner from '../../components/PageComponents/FooterBanner';
import About from '../../components/PageComponents/About';
import AboutHeader from '../../components/PageComponents/AboutHeader';

import './styles.css';

function AboutPage() {

  const gaEventTracker = useAnalyticsEventTracker('Quem somos');

  useEffect(() => {
    gaEventTracker.sendPageView('/quem-somos', 'Quem somos');
  }, []);

  return (
    <div id="about-page" className="container">
      <AboutHeader />
      <About />      
      <Footer />
    </div>
  );
}

export default AboutPage;
