import React from 'react';

import './styles.css';

const About: React.FC = () => {
  return (
    <div className='about'>
      <div className='about-content'>
        <div className='text-box'>
          <strong>INGRID DALILA ENGEL</strong>
          <p>Numeróloga, Psicóloga, Coach</p>
          <p>Paz e Harmonia!</p>
          <p>Desde criança senti que minha missão era estar a serviço
            das pessoas, apoiando-as em sua caminhada, fortalecendo
            seus talentos, entusiasmo, confiança para construção de
            uma vida mais feliz, com mais sentido e mais verdadeira.
            Para cumprir esta missão fui buscar maior consciência
            através de estudos, aprimoramentos, auto conhecimento e
            práticas de interiorização.</p>
          <p>Fiz psicologia, especialização em psicanálise e análise
            transacional, astrologia, numerologia, técnicas
            terapêuticas, de regressão e outras.</p>
          <p>Atuo na área há mais de 40 anos, tendo trabalhado em RH,
            nas áreas de seleção, treinamento e desenvolvimento em
            várias empresas, também como headhunter, em
            consultoria e como coaching, o que me trouxe vasta
            experiência no mundo corporativo.</p>
          <p>Atuei também na área clinica, como terapeuta em
            atendimentos individuais e com casais.</p>
          <p>Administrei cursos em várias áreas, dei aulas de pós
            graduação em RH, formei numerólogos, ministrei palestras
            e vivências em workshops.</p>
          <p>O meu propósito, através da consciência e do
            conhecimento é facilitar um caminho dentro do propósito
            maior, na verdade e mais feliz.</p>
          <p>Com carinho,</p>
          <p>Ingrid Dalila Engel</p>
          <div className='lista'>
            <ul>
              <li>Mais de 40 anos de profissão</li>
              <p>Consultora e Escritora em Numerologia</p>
              <li>31.600 Consulentes</li>
              <p>Uma lista atual com mais de 31.600 consulentes satisfeitos</p>
              <li>50.000 Mapas</li>
              <p>Já interpretou mais de 50.000 mapas numerológicos</p>
              <li>Autoridade Reconhecida</li>
              <p>3.000 cópias vendidas de seu livro autoral</p>
            </ul>
          </div>          
        </div>
        <div className='text-box'>
          <strong>JAIME BENEDETTI</strong>
          <p>Escritor, Publisher, Life Coach</p>
          <p>Nasceu na cidade de Espirito Santo do Pinhal (SP) em 10 de
            Março de 1955, filho de Jayme Estevam Benedetti e Odila
            Coghi Benedetti.</p>
          <p>Iniciou desde cedo como funcionário da empresa dos seus
            pais, uma pequena fábrica de máquinas agricolas e de uma
            fundição de ferro e metais não ferrosos. Também na
            fazenda da família atuou no desenvolvimento de muitas
            atividades na criação e seleção de gado holandês e
            produção de café.</p>
          <p>Logo na infância já despontava um lado artístico, mas
            nunca desenvolvido e até aos 36 anos sempre atuou como
            gerente de vendas e marketing na empresa. Com a falência
            da empresa da família, iniciou a escrever textos de 
            auto-ajuda para alguns portais da Internet, contando as suas
            experiências de vida. Escreveu durante 27 anos, centenas
            de textos, porém com pouca divulgação ao público.
            Teve dois filhos, Jaime e Ana Helena, frutos de seu
            primeiro casamento.</p>
          <p>Aos 39 anos, casou-se com Ingrid Dalila Engel, Psicóloga
            com larga experiência no atendimento clinico, e grande
            autoridade na interpretação de Mapas Numerológicos
            individuais, de casal e empresariais, nascida no Rio de
            Janeiro (RJ) em 30 de outubro de 1954, dando inicio a uma
            parceria nos estudos da Numerologia, lançando Livros,
            Cursos e Programas de Cálculos para utilização nos  
            trabalhos de atendimentos aos clientes.</p>
          <p>Passados 27 anos, enfim, obteve a autorização do mundo
            espiritual através dos seus Mentores "Ed" e "lahraré" a dar
            inicio ao lançamento das suas escritas, através da abertura
            das páginas na Internet Jaime Benedetti - Analogias &
            Crônicas e O Caçador de Sacerdotisas.</p>
          <p>Aqui começo a minha humilde jornada com</p>
          <p>O Despertar de uma Consciência.</p>
        </div>
      </div> 
    </div>
  );
};

export default About;