import React from "react";
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logos/logo.png';
import jaime from '../../../assets/images/fotos/jaime.png';
import ingrid from '../../../assets/images/fotos/ingrid.png';

import './styles.css'; 

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="header-content">
        <img id='logo' src={logo} alt="logo_truenumbers"/>
        <div className="fotos">
          <img id="ingrid" src={ingrid} alt="Ingrid"/>
          <img id="jaime" src={jaime} alt="Jaime"/>
        </div>
        <div className="text-box">
          <div className="info">
            <strong>INGRID DALILA ENGEL</strong>
            <p>Numeróloga, Psicóloga, Coach</p>
          </div>
          <div className="info">
            <strong>JAIME BENEDETTI</strong>
            <p>Escritor, Publisher, Life Coach</p>
          </div>
        </div>
        <Link to='/quem-somos' style={{textDecoration: 'none'}}>
          <button>QUEM SOMOS</button>
        </Link>        
      </div>
    </header>
  );
};

export default Header;
